<template>
  <base-section id="dealflow-features" class="secondary">
    <v-responsive class="mx-auto" max-width="1350">
      <v-container fluid>
        <v-row>
          <v-col
            v-for="card in cards"
            :key="card.title"
            cols="12"
            sm="4"
            md="3"
          >
            <base-info-card align="center" dark v-bind="card" />
          </v-col>
        </v-row>
      </v-container>
    </v-responsive>
  </base-section>
</template>

<script>
export default {
  name: "DealflowFeatures",
  data: () => ({
    cards: [
      {
        image: "admin-portal",
        title: "Admin Portal",
        text: "A centralized admin portal, user management tools, and security and compliance management capabilities.",
      },
      {
        title: "Valuation models",
        text: "DealFlow provides built-in and customizable valuation models, and integration with third-party valuation tools.",
        image: "valuation-models",
      },
      {
        image: "bi-analytics",
        title: "BI Analytics",
        text: "Provides reporting & analytics tools, data visualization and dashboards to track key metrics.",
      },
      {
        image: "secure-data-storage",
        title: "Secure data storage",
        text: "Encrypts sensitive data using AES 256-bit encryption to secure data at rest",
      },
    ],
  }),
};
</script>
